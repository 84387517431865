import { createAction } from "@reduxjs/toolkit";

import { IRecievedAdminProductsAction, ISaveProductAction, ISaveProductCategoryAction, ISaveSliderAction, ISetBooleanDataAction } from "./types/actions";

export const DELETE_PRODUCT = "admin/DELETE_PRODUCT";
export const DELETE_CATEGORY = "admin/DELETE_CATEGORY";
export const DELETE_SLIDER = "admin/DELETE_SLIDER";
export const LOAD_ALL_PRODUCTS = "admin/LOAD_ALL_PRODUCTS";
export const RCVD_ADMIN_PRODUCTS = "admin/RCVD_ADMIN_PRODUCTS";
export const SET_ADMIN_LOADING = "admin/SET_ADMIN_LOADING";
export const SET_CREATE_PRODUCT = "admin/SET_CREATE_PRODUCT";
export const SUBMIT_PRODUCT = "admin/SUBMIT_PRODUCT";
export const SUBMIT_PRODUCT_CATEGORY = "admin/SUBMIT_PRODUCT_CATEGORY";
export const SHOW_PRODUCT_MODAL = "admin/SHOW_PRODUCT_MODAL";
export const HIDE_PRODUCT_MODAL = "admin/HIDE_PRODUCT_MODAL";
export const SHOW_PRODUCT_CATEGORY_MODAL = "admin/SHOW_PRODUCT_CATEGORY_MODAL";
export const HIDE_PRODUCT_CATEGORY_MODAL = "admin/HIDE_PRODUCT_CATEGORY_MODAL";
export const SHOW_SLIDER_MODAL = "admin/SHOW_SLIDER_MODAL";
export const HIDE_SLIDER_MODAL = "admin/HIDE_SLIDER_MODAL";
export const SUBMIT_SLIDER = "admin/SUBMIT_SLIDER";

export const deleteProduct = createAction<string>(DELETE_PRODUCT);
export const deleteCategory = createAction<string>(DELETE_CATEGORY);
export const deleteSlider = createAction<string>(DELETE_SLIDER);
export const recievedAdminProduct = createAction<IRecievedAdminProductsAction>(RCVD_ADMIN_PRODUCTS);
export const loadAllProducts = createAction(LOAD_ALL_PRODUCTS);
export const setCreateProduct = createAction<ISetBooleanDataAction>(SET_CREATE_PRODUCT);
export const setAdminLoading = createAction<ISetBooleanDataAction>(SET_ADMIN_LOADING);
export const showProductModal = createAction(SHOW_PRODUCT_MODAL);
export const hideProductModal = createAction(HIDE_PRODUCT_MODAL);
export const showSliderModal = createAction(SHOW_SLIDER_MODAL);
export const hideSliderModal = createAction(HIDE_SLIDER_MODAL);
export const showProductCategoryModal = createAction(SHOW_PRODUCT_CATEGORY_MODAL);
export const hideProductCategoryModal = createAction(HIDE_PRODUCT_CATEGORY_MODAL);
export const submitProduct = createAction<ISaveProductAction>(SUBMIT_PRODUCT);
export const submitProductCategory = createAction<ISaveProductCategoryAction>(SUBMIT_PRODUCT_CATEGORY);
export const submitSlider = createAction<ISaveSliderAction>(SUBMIT_SLIDER);