import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Icon, Modal, Tab, TabPane} from 'semantic-ui-react';

import { isUserPopupVisible } from '../userSelectors';
import { login, setUserPopupVisible, signUp } from '../userActions';
import { ILogin } from '../types/login';
import { ISignUp } from '../types/signup';

import LoginForm from './login';
import SignUpForm from './signup';


export const UserPopup: React.FC<{}> = ({}) => {
    const dispatch = useDispatch();
    const isVisible = useSelector(isUserPopupVisible);
    const closeModal= () => {
        dispatch(setUserPopupVisible({value: false }))
    }
    const onLoginSubmit = (data: ILogin) => {
        dispatch(login({
            payload: data
        }))
    }
    const onSignupSubmit = (data: ISignUp) => {
        dispatch(signUp({
            payload: data
        }))
    }
    const panes = [
        {
          menuItem: 'Log In',
          render: () => <TabPane attached={false}>
            <LoginForm onSubmit={onLoginSubmit} />
          </TabPane>,
        },
        {
          menuItem: 'Sign UP',
          render: () => <TabPane attached={false}>
            <SignUpForm onSubmit={onSignupSubmit} />
          </TabPane>,
        },
      ]
    return (
        <Modal open={isVisible} className='cart-model'>
            <Modal.Header>
                Login / Sign Up
                <Button icon floated='right' onClick={closeModal}>
                    <Icon name='close' />
                </Button>
            </Modal.Header>
            <Modal.Content>
                <Container className='overflow-auto'>
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                </Container>
            </Modal.Content>
        </Modal>
    )
}