import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { fetchProductCategories } from "../../product/productActions";
import { isCreateProduct } from "../adminSelectors";
import { ISaveProductCategoryAction } from "../types/actions";
import { IProductRequest } from "../types/productRequest";
import { hideProductCategoryModal, setAdminLoading } from "../adminActions";


export function* saveProductCategory({ payload: { data, id } }: PayloadAction<ISaveProductCategoryAction>) {
    const create: boolean = yield select(isCreateProduct);
    try{
        if(create) {
            const response: unknown = yield save(data);
            if(response){
                yield put(fetchProductCategories());
                yield put(hideProductCategoryModal());
                yield put(setAdminLoading({
                    value: false
                }));
                alert("Product Category Created");
            }
        }
        else {
            const response: unknown = yield update(data, id);
            if(response){
                yield put(fetchProductCategories());
                yield put(hideProductCategoryModal());
                yield put(setAdminLoading({
                    value: false
                }));
                alert("Product Category Updated");
            }
        }
    }
    catch(ex: any) {
        alert( create? "Product Category Created":"Product Category Updated");
        yield put(fetchProductCategories());
        yield put(setAdminLoading({
            value: false
        }));
        console.warn(ex);
    }
}

const save = async(product: IProductRequest): Promise<any> => {
    const response = (await AxiosInstance.post<any>(`product-category/create`, product));
    return response.data;
}

const update = async(product: IProductRequest, id?: string): Promise<any> => {
    const response = (await AxiosInstance.put<any>(`product-category/update/${id}`, product));
    return response.data;
}