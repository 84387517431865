import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { IFetchProductsAction } from "../types/action";
import { IProductsData } from "../types/product";
import {  recievedProducts } from "../productActions";



export function* fetchProductsSaga({ payload: { page, total, category, newest } }: PayloadAction<IFetchProductsAction>) {
    try {
        const data: IProductsData = yield getProducts(page, total, category, newest);
        yield put(recievedProducts({
            data
        }));
    } catch(e) {
        console.error(e);
    }
}

const getProducts = async(page: number, total: number, category?: string, newest?: boolean): Promise<IProductsData> => {
    const response = (await AxiosInstance.get<IProductsData>(`products/filter?page=${page}&total=${total}&category=${category ||''}&newest=${newest}`));
    return response.data;
}