import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Label } from 'semantic-ui-react';

import { getCartCount } from '../cartSelectors';
import { setCartVisible } from '../cartActions';

export const CartIcon: React.FC<{}> = ({}) => {
    const count = useSelector(getCartCount);
    const dispatch = useDispatch();
    const openCart = () => dispatch(setCartVisible({
        value: true
    }));
    return (
        <Label onClick={openCart} color='green' >
            <Icon name='shopping cart' size='large'/>
            {count}
        </Label>
    )
}