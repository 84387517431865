import AWS from 'aws-sdk';
import * as React from 'react';

import AxiosFileUploadInstance from '../AxiosFileUploadInstance';
import { Config } from '../config';

const S3_BUCKET = "successful-web";
const REGION = "ap-south-1";

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_SECRET_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});


const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });


export const useFileUpload = () => {
    const [uploading, setUploading] = React.useState<boolean>(false);
    const uploadFile = (files: File[]) => {
        setUploading(true);
        return new Promise<string[]>((resolve, reject)=>{
            const uploadedUrls : string[] = [];
            files.forEach(file=>{
                
                const formData = new FormData();
                formData.append("file", file);
                AxiosFileUploadInstance.post('files/upload', formData).then((data: any)=>{
                    uploadedUrls.push(`${Config.baseUrl}files/${data.data.filePath}`);
                    if(uploadedUrls.length===files.length) {
                        setUploading(false);
                        resolve(uploadedUrls);
                    } 
                })
                .catch((err: any)=>{
                    if(err) {
                        console.warn(err);
                        reject(err.message);
                        setUploading(false);
                    }
                })
                // const ext = /(?:\.([^.]+))?$/.exec(file.name)?.[1];
                // s3.upload({
                //     Bucket: S3_BUCKET,
                //     Key: ext ? `${(new Date()).getTime()}.${ext}` : file.name,
                //     Body: file,
                //     ACL: 'public-read',
                // }, (err, data)=>{
                //     if(err) {
                //         console.warn(err);
                //         reject(err.message);
                //         setUploading(false);
                //     }
                //     else {
                //         uploadedUrls.push(data.Location);
                //         if(uploadedUrls.length===files.length) {
                //             setUploading(false);
                //             resolve(uploadedUrls);
                //         }   
                //     }  
                // })
            })
        })
    } 
    return {uploading, uploadFile};
}

