import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { Button } from 'semantic-ui-react';

import { IProductCategory } from '../../product/types/product';
import { ICategoryRequest } from '../types/categoryRequest';
import { isAdminLoading } from '../adminSelectors';
import { submitProductCategory } from '../adminActions';

import './styles/products.scss';


export interface ICategoryFormProps {
    category?: IProductCategory;
}

const renderCategoryForm: React.FC<InjectedFormProps<ICategoryRequest>> = ({
    pristine,
    submitting,
    handleSubmit
}) => {
    const isLoading = useSelector(isAdminLoading);
    return (
        <Form name={'product-form'} onSubmit={handleSubmit}>
            <h4 className="ui horizontal divider">
                Product Category Details
            </h4>
            <div className="input-group">
                <label>Category Name:</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="category" 
                    name="category" 
                    required 
                />
            </div>
            <div className="input-group">
                <label>Extra(Optional):</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="extra" 
                    name="extra" 
                />
            </div>
            <Button loading={isLoading} variant={"primary"} disabled={pristine || submitting || isLoading} type="submit" className="login-btn">Save Product Category</Button>
        </Form>
    )
}

const onSubmit = (values: ICategoryRequest, dispatch: any) => {
    dispatch(submitProductCategory({
        data: values,
        id: values?.id
    }));
  };

export const CategoryForm = connect(
    (_state, props: ICategoryFormProps) => {
        return {
            initialValues: props.category || {

            }
        }
    },
    null
)(reduxForm<ICategoryRequest>({
    form: 'category',
    enableReinitialize: true,
    onSubmit,
  })(renderCategoryForm));