import { createReducer } from '@reduxjs/toolkit';
import { compose, lensPath, set } from 'ramda';

import { IRecivedLoginResponseAction, IRecivedSignupResponseAction, ISetLoggedInUserAction, ISetUserBooleanAction } from "./types/action";
import { IUser, IUserState } from "./types/state";
import { login, recivedLoginResponse, recivedSignupResponse, setLoggedInUser, setUserPopupVisible, signUp } from './userActions';

const INITIAL_STATE: IUserState = {
    loading: false,
    visible: false,
};

const setUserPopupVisibleRed = ({ value }: ISetUserBooleanAction) => set<IUserState, boolean>(
    lensPath(['visible']), value
);

const setLoggedInUserRed = ({ user }: ISetLoggedInUserAction) => set<IUserState, IUser | undefined>(
    lensPath(['loggedInUser']), user
);

const setLoading = () => compose( set<IUserState, boolean>(
    lensPath(['loading']), true
),
set<IUserState, any|undefined>(lensPath(['loginResponse']), undefined),
set<IUserState, any|undefined>(lensPath(['signupResponse']), undefined)
);

const recivedLoginResponseRed = ({ response }: IRecivedLoginResponseAction) => compose(
    set<IUserState, boolean>(lensPath(['loading']), false),
    set<IUserState, any|undefined>(lensPath(['loginResponse']), response)
);

const recivedSignupResponseRed = ({ response }: IRecivedSignupResponseAction) => compose(
    set<IUserState, boolean>(lensPath(['loading']), false),
    set<IUserState, any|undefined>(lensPath(['signupResponse']), response)
);

export const userReducers = createReducer(INITIAL_STATE, (builder) => { 
    builder.addCase(setUserPopupVisible, (state, action) => setUserPopupVisibleRed(action.payload)(state))
    .addCase(setLoggedInUser, (state, action)=> setLoggedInUserRed(action.payload)(state))
    .addCase(login, (state, _action)=> setLoading()(state))
    .addCase(signUp, (state, _action)=> setLoading()(state))
    .addCase(recivedLoginResponse, (state, action)=> recivedLoginResponseRed(action.payload)(state))
    .addCase(recivedSignupResponse, (state, action)=> recivedSignupResponseRed(action.payload)(state))
})