import { createReducer } from '@reduxjs/toolkit';
import { compose, lensPath, set } from 'ramda';

import { IFetchProductsAction, IReceivedProductAction, IReceivedProductCategoriesAction, IReceivedProductsAction, IReceivedSlidersAction } from './types/action';
import { IProductState } from './types/state';
import { IProduct, IProductCategory, IProductsData } from './types/product';
import { fetchProduct, fetchProductCategories, fetchProducts, fetchSliders, recievedProduct, recievedProductCategories, recievedProducts, recievedSliders, resetProductState } from './productActions';
import { ISlider } from './types/slider';


const INITIAL_STATE: IProductState = {
    loading: false,
    activePage: undefined,
    productDetails: undefined,
    productData: undefined
};

const fetchProductRed = () => set<IProductState, boolean>(lensPath(['product', 'loading']), true);

const fetchProductsRed = ({ page }: IFetchProductsAction) => compose(
    set<IProductState, boolean>(lensPath(['loading']), true),
    set<IProductState, number|undefined>(lensPath(['activePage']), page)
);

const recievedProductRed = ({ product }: IReceivedProductAction) => compose(
    set<IProductState, boolean>(lensPath(['loading']), false),
    set<IProductState, IProduct|undefined>(lensPath(['productDetails']), product)
);

const recievedSlidersRed = ({ sliders }: IReceivedSlidersAction) => compose(
    set<IProductState, boolean>(lensPath(['loading']), false),
    set<IProductState, ISlider[]|undefined>(lensPath(['sliders']), sliders)
);

const recievedProductsRed = ({ data }: IReceivedProductsAction) => compose(
    set<IProductState, boolean>(lensPath(['loading']), false),
    set<IProductState, IProductsData|undefined>(lensPath(['productData']), data)
);

const recievedCategoriesRed = ({ categories }: IReceivedProductCategoriesAction) => compose(
    set<IProductState, boolean>(lensPath(['loading']), false),
    set<IProductState, IProductCategory[]|undefined>(lensPath(['categories']), categories)
);

const resetProductStateRed = () => compose(
    set<IProductState, boolean>(lensPath(['loading']), INITIAL_STATE.loading),
    set<IProductState, IProductsData|undefined>(lensPath(['productData']), INITIAL_STATE.productData),
    set<IProductState, IProduct|undefined>(lensPath(['productDetails']), INITIAL_STATE.productDetails),
    set<IProductState, number|undefined>(lensPath(['activePage']), INITIAL_STATE.activePage),
);

const productReducers = createReducer(INITIAL_STATE, (builder) => {
    builder.addCase(fetchProduct, (state, _action) => fetchProductRed()(state))
            .addCase(recievedProduct, (state, action) => recievedProductRed(action.payload)(state))
            .addCase(recievedProducts, (state, action) => recievedProductsRed(action.payload)(state))
            .addCase(resetProductState, (state, _action) => resetProductStateRed()(state))
            .addCase(fetchProducts, (state, action) => fetchProductsRed(action.payload)(state))
            .addCase(fetchSliders, (state, _action) => fetchProductRed()(state))
            .addCase(recievedSliders, (state, action) => recievedSlidersRed(action.payload)(state))
            .addCase(recievedProductCategories, (state, action) => recievedCategoriesRed(action.payload)(state))
            .addDefaultCase((_state, _action) => {})
});
export default productReducers;