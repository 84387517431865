// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 50%; /* Maintain aspect ratio */
}
.slider .slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
.slider .slide h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.slider .slide p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}
.slider .slide-content {
  max-width: 80%;
  margin: 0 auto;
}
.slider .slide-btn {
  display: inline-block;
  padding: 1.2rem 2rem;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 0.3rem;
  transition: background-color 0.3s;
}
.slider .slide-btn:hover {
  background-color: #2980b9;
}`, "",{"version":3,"sources":["webpack://./src/modules/home/components/styles/slider.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA,EAAA,0BAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AAER;AADQ;EACI,eAAA;EACA,mBAAA;AAGZ;AADQ;EACI,iBAAA;EACA,qBAAA;AAGZ;AAAI;EACI,cAAA;EACA,cAAA;AAER;AAAI;EACI,qBAAA;EACA,oBAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,qBAAA;EACA,iCAAA;AAER;AADQ;EACI,yBAAA;AAGZ","sourcesContent":[".slider {\n    position: relative;\n    overflow: hidden;\n    width: 100%;\n    padding-bottom: 50%; /* Maintain aspect ratio */\n    .slide {\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        background-size: cover;\n        background-position: center;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        text-align: center;\n        color: #fff;\n        h2 {\n            font-size: 2rem;\n            margin-bottom: 1rem;\n        }\n        p {\n            font-size: 1.2rem;\n            margin-bottom: 1.5rem;\n        }\n    }\n    .slide-content {\n        max-width: 80%;\n        margin: 0 auto;\n    }\n    .slide-btn {\n        display: inline-block;\n        padding: 1.2rem 2rem;\n        background-color: #3498db;\n        color: #fff;\n        text-decoration: none;\n        border-radius: 0.3rem;\n        transition: background-color 0.3s;\n        &:hover {\n            background-color: #2980b9;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
