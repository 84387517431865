import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { IProductCategory } from "../types/product";
import { recievedProductCategories } from "../productActions";

export function* fetchCategoriesSaga() {
    try {
        const categories: IProductCategory[] = yield getCategories();
        yield put(recievedProductCategories({
            categories
        }));
    } catch(e) {
        console.error(e);
    }
}

const getCategories = async(): Promise<IProductCategory[]> => {
    const response = (await AxiosInstance.get<IProductCategory[]>(`product-category/get-all`));
    return response.data;
}