// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  padding: 1.5rem 0.4rem;
  text-align: center;
  min-height: 10rem;
}
.section .section-header {
  padding: 0.5rem;
}
.section .section-header .sub-text {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 200;
  margin: 0;
}
.section .section-header .sub-text .header-text {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
}
.section .section-content {
  padding: 0.3rem 0.4rem;
}
.section.padding-top-8 {
  padding-top: 8rem !important;
}`, "",{"version":3,"sources":["webpack://./src/modules/home/components/styles/home.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAAI;EACI,eAAA;AAER;AADQ;EACI,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,SAAA;AAGZ;AAFY;EACI,kBAAA;EACA,mBAAA;EACA,SAAA;AAIhB;AAAI;EACI,sBAAA;AAER;AAAI;EACI,4BAAA;AAER","sourcesContent":[".section {\n    padding: 1.5rem 0.4rem;\n    text-align: center;\n    min-height: 10rem;\n    .section-header {\n        padding: 0.5rem;\n        .sub-text {\n            font-size: 0.75rem;\n            line-height: 1rem;\n            font-weight: 200;\n            margin: 0;\n            .header-text {\n                font-size: 1.25rem;\n                line-height: 1.5rem;\n                margin: 0;\n            }\n        }\n    }\n    .section-content {\n        padding: 0.3rem 0.4rem;\n    }\n    &.padding-top-8 {\n        padding-top: 8rem !important;\n    }\n    \n}\n// .image-big {\n//     img {\n//         width: 700px !important;\n//         max-width: 700px !important;\n//     }\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
