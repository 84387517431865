// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products .header {
  padding: 2rem 0.5rem;
}
.products .header p {
  font-size: 1.2rem;
}
.products .pagination-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/modules/product/components/styles/products.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;AAAR;AACQ;EACI,iBAAA;AACZ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAAR","sourcesContent":[".products {\n    .header {\n        padding: 2rem 0.5rem;\n        p {\n            font-size: 1.2rem;\n        }\n    }\n    .pagination-div {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding-top: 0.3rem;\n        padding-bottom: 0.3rem;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
