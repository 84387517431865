import { createAction } from "@reduxjs/toolkit";

import { ISetCartAction, ISetCartBooleanAction, IUpdateCartAction } from "./types/action";

export const UPDATE_CART = 'cart/ADD_CART';
export const SET_CART = 'cart/SET_CART';
export const SET_CART_VISIBLE = 'cart/SET_CART_VISIBLE';
export const SET_CART_LOADING = 'cart/SET_CART_LOADING';

export const updateCart = createAction<IUpdateCartAction>(UPDATE_CART);
export const setCart = createAction<ISetCartAction>(SET_CART);
export const setCartVisible = createAction<ISetCartBooleanAction>(SET_CART_VISIBLE);
export const setCartLoading = createAction<ISetCartBooleanAction>(SET_CART_LOADING);