import { createReducer } from '@reduxjs/toolkit';
import { compose, lensPath, set } from 'ramda';


import { ICartItem, ICartState } from './types/CartState';
import { ISetCartAction, ISetCartBooleanAction, IUpdateCartAction } from './types/action';
import { setCart, setCartLoading, setCartVisible, updateCart } from './cartActions';


const INITIAL_STATE: ICartState = {
    carts: [],
    loading: false,
    visible: false,
};

const updateCartRed = ({ cart }: IUpdateCartAction) => (state: ICartState) => {
    const index = state.carts.findIndex(c=>c.id===cart.id);
    const carts = [...state.carts];
    if(index>-1) {
        if(carts[index].quantity+cart.quantity>0) {
            carts[index].quantity+=cart.quantity;
        }
        else {
            carts.splice(index, 1);
        }
    }
    else {
        carts.push(cart);
    }
    state.carts = carts;
    return state;
}

const setCartRed = ({ carts }: ISetCartAction) => set<ICartState, ICartItem[]>(lensPath(['carts']), carts);
const setCartLoadingRed = ({ value }: ISetCartBooleanAction) => set<ICartState, boolean>(lensPath(['loading']), value);
const setCartVisibleRed = ({ value }: ISetCartBooleanAction) => set<ICartState, boolean>(lensPath(['visible']), value);

export const cartReducers = createReducer(INITIAL_STATE, (builder) => { 
    builder.addCase(updateCart, (state, action)=> updateCartRed(action.payload)(state))
    .addCase(setCart, (state, action)=> setCartRed(action.payload)(state))
    .addCase(setCartVisible, (state, action)=> setCartVisibleRed(action.payload)(state))
    .addCase(setCartLoading, (state, action)=> setCartLoadingRed(action.payload)(state))
    .addDefaultCase((_state, _action) => {})
});