import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IProduct } from '../../product/types/product';
import { isProductModalVisible } from '../adminSelectors';
import { deleteProduct, hideProductModal } from '../adminActions';

import { ProductForm } from './ProductForm';
import { Button } from 'semantic-ui-react';


export interface IProductModalProps {
    product?: IProduct;
}

export const ProductModal:React.FC<IProductModalProps> = ({
    product
}) => {
    const show = useSelector(isProductModalVisible);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideProductModal());
    }
    const deleteItem = () => {
        if(product?.id)
            if (confirm("Are you sure?") == true) {
                dispatch(deleteProduct(product?.id))
            }
    }
    return (
        <Modal show={show} size='lg' onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{product ? 'Update' : 'Create'} Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    product &&
                    <Button onClick={deleteItem} floated='right' circular color='red' icon='delete' />
                }
                {
                    show &&
                    <ProductForm product={product} />
                }
            </Modal.Body>
        </Modal>
    )
}