import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { IFetchProductAction } from "../types/action";
import { IProduct } from "../types/product";
import { recievedProduct } from "../productActions";



export function* fetchProductSaga({ payload: { id } }: PayloadAction<IFetchProductAction>) {
    try {
        const product: IProduct = yield getProduct(id);
        yield put(recievedProduct({
            product
        }));
    } catch(e) {
        console.error(e);
    }
}

const getProduct = async(id: string): Promise<IProduct> => {
    const response = (await AxiosInstance.get<IProduct>(`products/${id}`));
    
    return response.data;
}