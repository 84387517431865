import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormField } from 'semantic-ui-react';

import { getCategories } from '../../product/productSelectors';
import { IProductCategory } from '../../product/types/product';
import { isProductCategoryModalVisible } from '../adminSelectors';
import { deleteCategory, hideProductCategoryModal, setCreateProduct } from '../adminActions';


import { CategoryForm } from './CategoryForm';

export const CategoryModal:React.FC<{}> = () => {
    const show = useSelector(isProductCategoryModalVisible);
    const categories = useSelector(getCategories);
    const [category, setCategory] = React.useState<IProductCategory|undefined>(undefined)
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideProductCategoryModal());
    }
    const clearSelection = () => {
        setCategory(undefined);
        dispatch(setCreateProduct({
            value: true
        }));
    }
    React.useEffect(()=>{
        if(!show) {
            setCategory(undefined)
        }
    }, [show])
    const onChange = (event: React.SyntheticEvent<HTMLElement, Event>) => {
       const value = (event.target as any).value;
       console.log(event)
        if(value) {
            const cat = categories?.find(c=>c.id===value);
            if(cat) {
                setCategory(cat);
                dispatch(setCreateProduct({
                    value: false
                }));
            }
        }
    }
    const deleteItem = () => {
        if(category?.id)
            if (confirm("Are you sure?") == true) {
                dispatch(deleteCategory(category?.id));
            }
            
    }
    return (
        <Modal show={show} size='lg' onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{category ? 'Update' : 'Create'} Product Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                <Button size='small' onClick={clearSelection} floated='right'>Clear Selection</Button>
                    
                    <FormField 
                    className='padding-top-050'
                    fluid
                    control='select'
                    label='Select Category'
                    value={category?.id || ''} onChange={onChange}>
                            {[{
                                key: 'default',
                                text: '',
                                value: ''
                            },...(categories||[]).map(c=>({
                                key: c.id,
                                text: c.category,
                                value: c.id
                            }))].map(op=>(<option key={op.key} value={op.value}>{op.text}</option>))}
                        </FormField>
                    
                    <h4 className="ui horizontal divider">
                        OR Create
                    </h4>
                    {
                    category &&
                        <Button onClick={deleteItem} floated='right' circular color='red' icon='delete' />
                    }
                    {
                        show &&
                        (
                            category ?
                            <CategoryForm category={category} />
                            :
                            <CategoryForm category={undefined} />
                        )
                    }
                </div>
                
            </Modal.Body>
        </Modal>
    )
}