import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { recievedSliders } from "../productActions";
import { ISlider } from "../types/slider";



export function* fetchSlidersSaga() {
    try {
        const sliders: ISlider[] = yield getSliders();
        yield put(recievedSliders({
            sliders
        }));
    } catch(e) {
        console.error(e);
    }
}

const getSliders = async(): Promise<ISlider[]> => {
    const response = (await AxiosInstance.get<ISlider[]>('slider/get'));
    return response.data;
}