import * as React from 'react';
import DocumentTitle from 'react-document-title';

export interface BodyProps extends React.PropsWithChildren<{}> {
    title: string;
}
 
export const Body: React.FC<BodyProps> = ({
    children,
    title,
}) => {
    return ( 
        <DocumentTitle title={title}>
            <div>
                {children}
            </div>
        </DocumentTitle>
    
    )
}