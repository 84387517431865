import * as React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { BounceLoader } from 'react-spinners';

import { getProducts, isProductLoading } from '../productSelectors';
import { fetchProducts } from '../productActions';

import { ProductSlideItem } from './product-silde-item';
import './styles/product-slider.scss';

export const ProductSlider: React.FC<{}> = ({
    
}) => {
    const dispatch = useDispatch();
    const products = useSelector(getProducts);
    const isLoading = useSelector(isProductLoading);
    
    React.useEffect(()=>{
      dispatch(fetchProducts({
        page: 0,
        total: 20,
        newest: true
      }))
    }, [])
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      if(isLoading || !products) 
        return <LoadingOverlay 
            active
            spinner={<BounceLoader />}
        />
    return (
        <div className='product-slider'>
            <Carousel responsive={responsive} pauseOnHover renderDotsOutside renderButtonGroupOutside autoPlay swipeable>
                {
                    [...products].map(product=>(
                      <div key={product.id}>
                        <ProductSlideItem key={product.id} product={product} />
                      </div>
                    ))
                }
            </Carousel>
        </div>
    )
}