import { put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import AxiosLoginInstance from "../../../AxiosLoginIntance";
import { ISignUpAction } from "../types/action";
import { IUser } from "../types/state";
import { recivedSignupResponse } from "../userActions";
import { ISignUp } from "../types/signup";


export function* signUpSaga({ payload }: PayloadAction<ISignUpAction>) {
    try {
        if(payload?.payload) {
            const user: IUser = yield signUp(payload.payload);
            yield put(recivedSignupResponse({
                response: {
                    status: 'success',
                    user
                }
            }));
        }
    }catch(ex: any) {
        yield put(recivedSignupResponse({
            response: {
                status: 'error',
                message: ex.message
            }
        }));
    }
}

const signUp = async(data: ISignUp) => {
    const response = (await AxiosLoginInstance.post<IUser>(`/user/user-api/signup`, data));
    return response.data;
}