import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormField } from 'semantic-ui-react';

import { getSliders } from '../../product/productSelectors';
import { ISlider } from '../../product/types/slider';
import { isSliderModalVisible } from '../adminSelectors';
import { deleteSlider, hideSliderModal, setCreateProduct } from '../adminActions';

import { SliderForm } from './SliderForm';



export const SliderModal:React.FC<{}> = () => {
    const show = useSelector(isSliderModalVisible);
    const sliders = useSelector(getSliders);
    const [slider, setSlider] = React.useState<ISlider|undefined>(undefined)
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideSliderModal());
    }
    const clearSelection = () => {
        setSlider(undefined);
        dispatch(setCreateProduct({
            value: true
        }));
    }
    React.useEffect(()=>{
        if(!show) {
            setSlider(undefined)
        }
    }, [show])
    const onChange = (event: React.SyntheticEvent<HTMLElement, Event>) => {
       const value = (event.target as any).value;
       console.log(event)
        if(value) {
            const cat = sliders?.find(c=>c.id===value);
            if(cat) {
                setSlider(cat);
                dispatch(setCreateProduct({
                    value: false
                }));
            }
        }
    }
    const deleteItem = () => {
        if(slider?.id)
            if (confirm("Are you sure?") == true) {
                dispatch(deleteSlider(slider?.id));
            }
            
    }
    return (
        <Modal show={show} size='lg' onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{slider ? 'Update' : 'Create'} Slider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                <Button size='small' onClick={clearSelection} floated='right'>Clear Selection</Button>
                    
                    <FormField 
                    className='padding-top-050'
                    fluid
                    control='select'
                    label='Select Slider'
                    value={slider?.id || ''} onChange={onChange}>
                            {[{
                                key: 'default',
                                text: '',
                                value: ''
                            },...(sliders||[]).map(c=>({
                                key: c.id,
                                text: c.text,
                                value: c.id
                            }))].map(op=>(<option key={op.key} value={op.value}>{op.text}</option>))}
                        </FormField>
                    
                    <h4 className="ui horizontal divider">
                        OR Create
                    </h4>
                    {
                    slider &&
                        <Button onClick={deleteItem} floated='right' circular color='red' icon='delete' />
                    }
                    {
                        show &&
                        (
                            slider ?
                            <SliderForm slider={slider} />
                            :
                            <SliderForm slider={undefined} />
                        )
                    }
                </div>
                
            </Modal.Body>
        </Modal>
    )
}