import * as React from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Card, CardContent, CardDescription, CardHeader, CardMeta, Header, HeaderSubheader, Image, Rating } from 'semantic-ui-react';

import { ProductSlider } from '../../product/components/product-slider';
import { Body } from '../../utils/body';

import { Slider } from './slider';
import './styles/home.scss';


export const Home: React.FC<{}> = ({}) =>{
    const download  = () => {
        const link = document.createElement('a');
        link.download = "Product Catalogue";
        link.href = `${process.env.PUBLIC_URL}/product_catalogue.pdf`;
        link.click();
    }
    return (
        <Body title='Successful - Home'>
            <Slider />
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image-1.png'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%'
            }}>
                <Container>
                    <div className='section'>
                        <div className='section-header'>
                        <Header as={"h3"}>
                                        <HeaderSubheader>
                                            Enjoy the best designs
                                        </HeaderSubheader>
                                        Exciting Notebooks
                                    </Header>
                        </div> 
                        <div className='section-content'>
                            <ProductSlider />
                        </div>
                    </div>
                </Container>
            </div>
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image-2.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }} className='mt-3'>
                <Container>
                    <Row>
                        <Col md={4} xs={12}>
                            <div className='section padding-top-8'>
                                <div className='section-header'>
                                <Header as={"h3"}>
                                        Choose your
                                </Header>
                        
                                </div> 
                                <div className='section-content'>
                                    <Header className='text-white' as={"h1"}>Premium Cover Designs</Header>
                                    <Button onClick={download} size='lg'>Download Our Catalogue</Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} xs={12}>
                            <Image className='image-big' centered wrapped size='massive' src={`${process.env.PUBLIC_URL}/123.png`} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image-2.png'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
            }}>
                <Container>
                    <div className='section'>
                        <div className='section-header'>
                            <Header as={"h3"} className='text-white'>
                                Subscribe to Our Newsletter
                            </Header>
                        </div> 
                        <div className='section-content'>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Email"
                                    aria-label="Recipient's Email"
                                    aria-describedby="basic-addon1"
                                />
                                <Button variant="outline-info" id="button-addon1">
                                    Subscribe
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
                </Container>
            </div>
            <div>
                <Image centered wrapped fluid src={`${process.env.PUBLIC_URL}/background_11zon.jpg`} />
            </div>
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image-2.png'})`,
                backgroundRepeat: 'no-repeat',
            }}>
                <Container>
                    <div className='section'>
                        <div className='section-header'>
                            <Header as={"h3"} className='text-white'>
                                Testimonials 
                            </Header>
                        </div> 
                        <div className='section-content'>
                        <Card centered>
                        <CardContent>
                            <CardHeader>Abhinav</CardHeader>
                            <CardMeta>Customer</CardMeta>
                            <CardDescription>
                            Loved the look of my new notebooks! I would highly recommend your website to anyone who likes to design their notebooks.
                                
                            </CardDescription>
                            <Rating icon='star' defaultRating={5} maxRating={5} />
                        </CardContent>
                        </Card>
                        </div>
                    </div>
                </Container>
            </div>
        </Body>
    )
}