import { all, call, takeLatest } from 'redux-saga/effects';

import { FETCH_PRODUCT, FETCH_PRODUCTS, FETCH_PRODUCT_CATEGORIES, FETCH_SLIDERS } from './modules/product/productActions';
import { fetchProductSaga } from './modules/product/sagas/fetchProductSaga';
import { fetchProductsSaga } from './modules/product/sagas/fetchProductsSaga';
import { fetchCategoriesSaga } from './modules/product/sagas/fetchCategoriesSaga';
import { fetchSlidersSaga } from './modules/product/sagas/fetchSlidersSaga';
import { USER_LOGIN, USER_SIGNUP } from './modules/user/userActions';
import { loginSaga } from './modules/user/sagas/loginSaga';
import { signUpSaga } from './modules/user/sagas/signUpSaga';
import { DELETE_CATEGORY, DELETE_PRODUCT, DELETE_SLIDER, LOAD_ALL_PRODUCTS, SHOW_PRODUCT_CATEGORY_MODAL, SHOW_PRODUCT_MODAL, SHOW_SLIDER_MODAL, SUBMIT_PRODUCT, SUBMIT_PRODUCT_CATEGORY, SUBMIT_SLIDER } from './modules/admin/adminActions';
import { loadCategoriesAdmin } from './modules/admin/sagas/loadCategories';
import { loadAllProducts } from './modules/admin/sagas/loadAllProducts';
import { saveProduct } from './modules/admin/sagas/saveProduct';
import { saveProductCategory } from './modules/admin/sagas/saveProductCategory';
import { deleteCategory } from './modules/admin/sagas/deleteCategory';
import { deleteProduct } from './modules/admin/sagas/deleteProduct';
import { deleteSlider } from './modules/admin/sagas/deleteSlider';
import { saveSlider } from './modules/admin/sagas/saveSlider';
import { loadSlidersAdmin } from './modules/admin/sagas/loadSliders';
import { checkLogin } from './modules/user/sagas/checkLogin';

export function* rootSaga() {
    yield all([
        call(fetchCategoriesSaga),
        call(checkLogin),
        takeLatest(FETCH_PRODUCT, fetchProductSaga),
        takeLatest(FETCH_PRODUCTS, fetchProductsSaga),
        takeLatest(USER_LOGIN, loginSaga),
        takeLatest(USER_SIGNUP, signUpSaga),
        takeLatest(FETCH_PRODUCT_CATEGORIES, fetchCategoriesSaga),
        takeLatest(FETCH_SLIDERS, fetchSlidersSaga),
        takeLatest(SHOW_PRODUCT_MODAL, loadCategoriesAdmin),
        takeLatest(SHOW_SLIDER_MODAL, loadSlidersAdmin),
        takeLatest(SHOW_PRODUCT_CATEGORY_MODAL, loadCategoriesAdmin),
        takeLatest(LOAD_ALL_PRODUCTS, loadAllProducts),
        takeLatest(SUBMIT_PRODUCT, saveProduct),
        takeLatest(SUBMIT_PRODUCT_CATEGORY, saveProductCategory),
        takeLatest(SUBMIT_SLIDER, saveSlider),
        takeLatest(DELETE_CATEGORY, deleteCategory),
        takeLatest(DELETE_PRODUCT, deleteProduct),
        takeLatest(DELETE_SLIDER, deleteSlider),
    ]);
}