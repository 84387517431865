
import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { Header } from 'semantic-ui-react';

import { fetchSliders } from '../../product/productActions';
import { getSliders, isProductLoading } from '../../product/productSelectors';

import './styles/slider.scss';




export const Slider: React.FC<{}> = ({

}) =>  {
    const dispatch = useDispatch();
    const sliders = useSelector(getSliders);
    const isLoading = useSelector(isProductLoading);
    React.useEffect(()=>{;
        dispatch(fetchSliders());
    }, []);    
    if(isLoading || !sliders) 
        return <LoadingOverlay 
            active
            spinner={<BounceLoader />}
        />
    return <div className="slider">
        <Carousel fade>  
        {
            [...sliders].map(slider=>(
                <Carousel.Item key={slider.link}>
                    <img
                        className="d-block w-100"
                        src={slider.slideURL}
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                        <Header as={'h2'} className='text-white text-right'>{slider.text}</Header>
                        {
                            slider.link &&
                            <Link className='slide-btn' to={slider.link}>Explore</Link>
                        }
                    </Carousel.Caption> */}
                </Carousel.Item>
            ))
        }
        </Carousel>
    </div>
}