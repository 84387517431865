import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import productReducers from './modules/product/productReducers';
import { cartReducers } from './modules/cart/cartReducers';
import { userReducers } from './modules/user/userReducers';
import { adminReducers } from './modules/admin/adminReducers';

export const rootReducers = combineReducers({
    form: formReducer,
    product: productReducers,
    cart: cartReducers,
    user: userReducers,
    admin: adminReducers,
});