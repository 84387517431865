import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Icon, Image, Input, Item, Modal } from 'semantic-ui-react';

import { getCart, isCartLoading, isCartVisible } from '../cartSelectors';
import { setCartVisible, updateCart } from '../cartActions';
import { ICartItem } from '../types/CartState';

export const CartComponent: React.FC<{}> = ({}) => {
    const dispatch = useDispatch();
    const cartItems = useSelector(getCart);
    const isLoading = useSelector(isCartLoading);
    const isVisible = useSelector(isCartVisible);
    const total = React.useMemo(()=>{
        return cartItems.reduce((a,b)=> a+(b.price * b.quantity), 0);
    }, [cartItems]);
    const closeModal = () => {
        dispatch(setCartVisible({
            value: false
        }));
    }
    const updateItem = (item: ICartItem, type: 'plus'|'minus') => () => {
        dispatch(updateCart({
            cart: {
                ...item, quantity: type==='plus' ? 1 : -1 
            }
        }));
    } 
    if(isLoading) 
        return <></>
    return (
        <Modal open={isVisible} className='cart-model'>
            <Modal.Header>
                Cart
                <Button icon floated='right' onClick={closeModal}>
                    <Icon name='close' />
                </Button>
            </Modal.Header>

            {
                cartItems.length > 0 ? (
                    <>
                        <Item.Group divided>
                            {cartItems.map(item => (
                                <Item key={item.id}>
                                    <Item.Image size='tiny' src={item.productImage} />
                                    <Item.Content verticalAlign='middle'>
                                        <Item.Header>{item.productName}</Item.Header>
                                        <Item.Meta>
                                            <span>x{item.quantity}</span>
                                        </Item.Meta>
                                        <div className='quanity-group'>
                                            <Button
                                                negative 
                                                className='quan-buttons'
                                                onClick={updateItem(item, 'minus')} 
                                            > 
                                                <Icon name='minus' /> 
                                            </Button>
                                            <Input 
                                                className='input-quanity'
                                                value={item.quantity} 
                                            />
                                            <Button
                                                positive 
                                                className='quan-buttons'
                                                onClick={updateItem(item, 'plus')} 
                                            > 
                                                <Icon name='plus' /> 
                                            </Button>
                                        </div>
                                         <Item.Extra className='item-total'>
                                            <Header floated='right'>₹{item.price*item.quantity}</Header>
                                        </Item.Extra>
                                    </Item.Content>
                                </Item>
                            ))}
                        </Item.Group>
                        <Modal.Actions className='model-bottom'>
                            <Button  
                                basic 
                                negative  
                                floated='left' 
                            >
                                Empty Cart
                            </Button>
                            <Button  
                                compact 
                                negative
                                floated='left' 
                            >
                                Proceed To Checkout
                            </Button>
                            <Header floated='right'>₹{total}</Header>
                        </Modal.Actions>
                    </>
                ) :
                (
                    <>
                        <Modal.Content image>
                            <Image wrapped size='huge' src={''} />
                            <Modal.Description>
                                <Header>Your Cart is currently Empty</Header>
                                <p>
                                    It would make you very happy if you added an item to the cart
                                </p>
                            </Modal.Description>
                        </Modal.Content>
                    </>
                )
            }
            
        </Modal>
    )
}