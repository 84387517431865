import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { hideProductCategoryModal, setAdminLoading } from "../adminActions";
import { fetchProductCategories } from "../../product/productActions";


export function* deleteCategory({ payload } :PayloadAction<string>) {
    try{
        yield deleteData(payload);
        yield put(fetchProductCategories());
        yield put(hideProductCategoryModal());
        yield put(setAdminLoading({
            value: false
        }));
        alert("Category Deleted");
    }
    catch(err: any) {
        alert("Error Occured!, please try again later!");
        console.warn(err);
    }
}

const deleteData = async(id: string): Promise<any> => {
    (await AxiosInstance.delete<any>(`product-category/delete/${id}`));
    return "deleted";
}