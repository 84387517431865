import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { IProduct } from "../../product/types/product";
import { recievedAdminProduct } from "../adminActions";

export function* loadAllProducts() {
    try {
        const data: IProduct[] = yield getProducts();
        yield put(recievedAdminProduct({
            products: data
        }));
    } catch(e) {
        console.error(e);
    }
}

const getProducts = async(): Promise<IProduct[]> => {
    const response = (await AxiosInstance.get<IProduct[]>(`products/get-all`));
    return response.data;
}