import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { Button } from 'semantic-ui-react';

import { FileUploadField } from '../../../utils/FileUploadField';
import { ISlider } from '../../product/types/slider';
import { ISliderRequest } from '../types/sliderRequest';
import { isAdminLoading } from '../adminSelectors';
import { submitSlider } from '../adminActions';

import './styles/products.scss';

export interface ISliderFormProps {
    slider?: ISlider;
}

const renderSliderForm: React.FC<InjectedFormProps<ISliderRequest>> = ({
    pristine,
    submitting,
    handleSubmit
}) => {
    const isLoading = useSelector(isAdminLoading);
    return (
        <Form name={'slider-form'} onSubmit={handleSubmit}>
            <h4 className="ui horizontal divider">
                Slider Details
            </h4>
            <div className="input-group">
                <label>Slider Image:</label>
                <Field 
                    component={FileUploadField}
                    id="slideURL" 
                    name="slideURL" 
                    required 
                />
            </div>
            <div className="input-group">
                <label>Text(Optional):</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="text" 
                    name="text" 
                />
            </div>
            <div className="input-group">
                <label>Link(Optional):</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="link" 
                    name="link" 
                />
            </div>
            <Button loading={isLoading} variant={"primary"} disabled={pristine || submitting || isLoading} type="submit" className="login-btn">Save Slider</Button>
        </Form>
    )
}

const onSubmit = (values: ISliderRequest, dispatch: any) => {
    dispatch(submitSlider({
        data: {
            ...values,
            slideURL: Array.isArray(values.slideURL) ? values.slideURL[0] : values.slideURL
        },
        id: values?.id
    }));
  };

export const SliderForm = connect(
    (_state, props: ISliderFormProps) => {
        return {
            initialValues: props.slider || {

            }
        }
    },
    null
)(reduxForm<ISliderRequest>({
    form: 'slider-form',
    enableReinitialize: true,
    onSubmit,
  })(renderSliderForm));