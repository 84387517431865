import { put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { Cookies } from 'react-cookie';

import AxiosLoginInstance from "../../../AxiosLoginIntance";
import { ILogInAction } from "../types/action";
import { ILogin } from "../types/login";
import { IUser } from "../types/state";
import { recivedLoginResponse, setLoggedInUser, setUserPopupVisible } from "../userActions";
import { Config } from "../../../config";

const cookies = new Cookies(null, { path: '/' });

export function* loginSaga({ payload }: PayloadAction<ILogInAction>) {
    try {
        if(payload?.payload) {
            const user: {userData: IUser, jwt: string} = yield login(payload.payload);
            const host = location.host;
            let domain = host;
            
            // if(host.split('.').length === 0) {
            //     domain = host;
            // }
            // else {
            //     let domainParts = host.split('.');
            //     domainParts.shift();
            //     domain = '.'+location.host;
            // }
            // alert(domain)
            cookies.set('token', user.jwt);
            
            yield put(recivedLoginResponse({
                response: {
                    status: 'success',
                    user: user.userData
                }
            }));
            yield put(setUserPopupVisible({value: false}));
            yield put(setLoggedInUser({ user: user.userData }));
            if(user.userData.roles?.includes("admin")) {
                window.history.pushState({},'',"/admin/products")
            }
        }
    }catch(ex: any) {
        yield put(recivedLoginResponse({
            response: {
                status: 'error',
                message: ex.message
            }
        }));
        console.warn(ex);
    }
}

const login = async(data: ILogin) => {
    const response = (await AxiosLoginInstance.post<IUser>(`/user/user-api/login`, data));
    return response.data;
}