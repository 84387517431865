import { path, pathOr } from 'ramda';

import { IAppState } from "../../AppState";
import { IUser } from './types/state';

export const isUserPopupVisible: (state: IAppState)=> boolean = pathOr(false, ['user', 'visible']);

export const isUserLoading: (state: IAppState)=> boolean = pathOr(false, ['user', 'loading']);

export const getLoggedInUser: (state: IAppState)=> IUser | undefined = path(['user', 'loggedInUser']);

export const getLoginResponse: (state: IAppState)=> any | undefined = path(['user', 'loginResponse']);

export const getSignupResponse: (state: IAppState)=> any | undefined = path(['user', 'signupResponse']);