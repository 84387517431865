import axios from "axios";

import { Config } from "./config";


const AxiosFileUploadInstance = axios.create({
  baseURL : Config.baseUrl,
  withCredentials: true,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "multipart/form-data",
    timeout : 1000,
  }, 
  // .. other options
});

export default AxiosFileUploadInstance;