import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Input, List, ListContent, ListIcon, ListItem } from 'semantic-ui-react';

import { useFileUpload } from './uploadFile';


export const FileUploadField: React.FC<WrappedFieldProps> = ({
    input,
}) => {
    const {uploading, uploadFile} = useFileUpload();
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       
        const files = e.target.files;
        if(files ) {
            const fileList: File[] = [];
            for(let i=0;i<files.length;i++) {
                if(files.item(i))
                    fileList.push(files.item(i) as File)
            }
            uploadFile(fileList).then(urls=>{
                let values: string[]= JSON.parse(JSON.stringify((input.value || []) as string[]));
                values = values.concat(urls);
                console.log(urls);
                input.onChange(values);
            }).catch(err=>{
                console.warn(err);
                alert(JSON.stringify(err));
            })
        }
      };
    const onRemove= (index: number) => () => {
        const values: string[]= JSON.parse(JSON.stringify(input.value as string[]));
        values.splice(index, 1);
        input.onChange(values);
    }
    return (
        <div>
            <Input loading={uploading} disabled={uploading} type="file" multiple onChange={handleFileChange} />
            <List>
                {
                    (input.value ? typeof input.value==='string' ? [input.value] : input.value : []).map((file: string, ind: number)=>(
                        <ListItem key={ind}>
                            <ListContent>
                                <a href={file} rel="noreferrer" target='_blank'>{file}</a>
                            </ListContent>
                            <ListIcon onClick={onRemove(ind)} name='remove circle' />
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}