import { put } from "redux-saga/effects";

import AxiosLoginInstance from "../../../AxiosLoginIntance";
import { IUser } from "../types/state";
import { setLoggedInUser } from "../userActions";

export function* checkLogin() {
    try {
        const user: IUser = yield check();
        if(user) {
            yield put(setLoggedInUser({ user }));
        }
    }
    catch(_err){

    }
}

const check = async() => {
    const response = (await AxiosLoginInstance.get<IUser>(`/user/user-api/whoami`));
    return response.data;
}