
import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { ISignUp } from '../types/signup';
import { getSignupResponse, isUserLoading } from '../userSelectors';

import './styles/login.scss';






const signUp: React.FC<InjectedFormProps<ISignUp>> = ({
    handleSubmit,
    pristine,
    submitting
}) => {
    const isLoading = useSelector(isUserLoading);
    const response = useSelector(getSignupResponse);
    // const [otpForEmail, setOtpForEmail] = React.useState('');
    // const [otpForPhone, setOtpForPhone] = React.useState('');
    // const [showEmailotp, setShowEmailotp] = React.useState(false);
    // const [showPhoneotp, setShowPhoneotp] = React.useState(false);
    // const onChangeField = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if(type==='email') {
    //         setOtpForEmail(event.target.value);
    //     }
    //     else if(type==='phone') {
    //         setOtpForPhone(event.target.value);
    //     }
    // }
    // const sendOtp = (type: string) => () => {
    //     if(type==='email') {
    //         setShowEmailotp(true);
    //     }
    //     else if(type==='phone') {
    //         setShowPhoneotp(true);
    //     }
    // }
    return (
    <div className="login-container">
        <Form onSubmit={handleSubmit}>
            {
                response ?
                response.status==='error' ?
                <Alert variant='danger'>
                    {response.message}
                </Alert>
                :
                <Alert variant='success'>
                    {"Account has been created successfully, please login with the email and password"}
                </Alert>
                :
                <></>
            }
            
            <div className='input-group'>
                <label>First Name:</label>
                <Field component={"input"} type="text" id="firstName" name="firstName" required />
            </div>
            <div className='input-group'>
                <label>Last Name:</label>
                <Field component={"input"} type="text" id="lastName" name="lastName" required />
            </div>
            <div className="input-group">
                <label>Email:</label>
                <Field component={"input"} type="email" id="email" name="email" required />
                {/* <div className='input-button-group'>
                    
                    <button onClick={sendOtp('email')} type='button' className='otp-button'>Send OTP</button>
                </div> */}
                {/* <div className={classnames('input-button-group',{
                    ['hidden']: !showEmailotp
                })}>
                    <input value={otpForEmail} onChange={onChangeField('email')} />
                    <button type='button' className='verify-button'>Verify</button>
                </div> */}
            </div>

            <div className="input-group">
                <label>Phone:</label>
                <Field component={"input"} type="tel" id="phone" name="phone" required />
                {/* <div className='input-button-group'>
                    
                    <button onClick={sendOtp('phone')} type='button' className='otp-button'>Send OTP</button>
                </div> */}
                {/* <div className={classnames('input-button-group',{
                    ['hidden']: !showPhoneotp
                })}>
                    <input value={otpForPhone} onChange={onChangeField('phone')} />
                    <button type='button' className='verify-button'>Verify</button>
                </div> */}
            </div>

            <div className="input-group">
                <label>Password:</label>
                <Field component={"input"} type="password" id="password" name="password" required />
            </div>
            <div className='input-group'>
                <label>Address:</label>
                <Field component={"input"} type="text" id="addresses[0].address" name="addresses[0].address" required />
            </div>
            <div className='input-group'>
                <label>City:</label>
                <Field component={"input"} type="text" id="addresses[0].city" name="addresses[0].city" required />
            </div>
            <div className='input-group'>
                <label>Pincode:</label>
                <Field component={"input"} type="text" id="addresses[0].zip" name="addresses[0].zip" required />
            </div>
            <div className='input-group'>
                <label>State:</label>
                <Field component={"input"} type="text" id="addresses[0].state" name="addresses[0].state" required />
            </div>
            <Button primary loading={isLoading} variant={"primary"} disabled={pristine || submitting || isLoading} type="submit" className="login-btn">Sign Up</Button>
        </Form>
    </div>
    )
}

const SignUpForm = reduxForm<ISignUp>({
    initialValues: {
        password: '',
        address: '',
        city: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        pincode: '',
        state: ''
    },
    form: 'signup'
  })(signUp); 
  
export default SignUpForm;