
import classNames from 'classnames';
import * as React from 'react';
import { Row, Col, Container } from 'react-bootstrap'; 
import LoadingOverlay from 'react-loading-overlay-ts';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { Body } from '../../utils/body';
import { updateCart } from '../../cart/cartActions';
import { fetchProduct, resetProductState } from '../productActions';
import { getProduct, isProductLoading } from '../productSelectors';
import { IProductSize } from '../types/product';


import './styles/product.scss';




export type ProductProps = {
    id: string;
}

export const Product: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const loading = useSelector(isProductLoading);
    const product = useSelector(getProduct);
    const [selectedSize, setSelectedSize] = React.useState<IProductSize|undefined>();
    const selectSize = (size: IProductSize) => () => {
        setSelectedSize(size);
    }
    const responsive = {
        desk: {
          breakpoint: { max: 4000, min: 0 },
          items: 1
        }
      };
    const { id } = useParams<ProductProps>();
    React.useEffect(()=>{
        if(id) {
            dispatch(fetchProduct({
                id
            }));
        }
        return (()=>{
            dispatch(resetProductState);
        })
    }, [id])
    React.useEffect(()=>{
        if(product) {
            setSelectedSize(product.sizes[0]);
        }
    },[product])
    const addToCart = () => {
        if(selectedSize && product && selectedSize.inStock)
            dispatch(updateCart({
                cart: {
                    id: selectedSize.id,
                    price: selectedSize.price,
                    productImage: selectedSize.imageUrl[0] || product.imageUrl[0],
                    productName: `${product.name} - ${selectedSize.title}`,
                    quantity: 1
                }
            }))
    }
    if(loading || !product) 
        return <LoadingOverlay 
            active
            spinner={<BounceLoader />}
        />
    return (
        <Body title={`Successful  - ${product.name}`}>
            <Container style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
            {
                product &&
                <Row>
                    <Col md={6} xs={12}>
                        <Carousel responsive={responsive} pauseOnHover renderDotsOutside>
                        
                            {
                                [...(selectedSize?.imageUrl||[]), ...(product.imageUrl ? product.imageUrl : [])].map(image=>(
                                    <div key={image} style={{textAlign: 'center', overflow: 'hidden'}}>
                                        <InnerImageZoom className='image' src={image} />
                                    </div>
                                ))
                            }
                        </Carousel>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className="product-description">
                            <span>{product.category?.category}</span>
                            <h1>{product.name} - {selectedSize?.title}</h1>
                            <p>{selectedSize?.description}</p>
                        </div>
                        <div className="product-configuration">
                            {/* <div className="product-color">
                                <span>Color</span>
                        
                                <div className="color-choose">
                                <div>
                                    <input data-image="red" type="radio" id="red" name="color" value="red" checked />
                                    <label><span></span></label>
                                </div>
                                <div>
                                    <input data-image="blue" type="radio" id="blue" name="color" value="blue" />
                                    <label><span></span></label>
                                </div>
                                <div>
                                    <input data-image="black" type="radio" id="black" name="color" value="black" />
                                    <label><span></span></label>
                                </div>
                                </div>
                        
                            </div> */}
                            <div className="cable-config">
                                <span>Configuration</span>
                        
                                <div className="cable-choose">
                                    {
                                        product.sizes.map(size=>(
                                            <button key={size.id} className={classNames({['active']: selectedSize?.id===size.id})} onClick={selectSize(size)}>{size.title}</button>
                                        ))
                                    }
                                </div>
                        
                                {/* <a href="#">How to configurate your headphones</a> */}
                            </div>
                        </div>
                        <div className="product-price">
                            <span className='mrp'>₹{selectedSize?.mrp}</span><span>₹{selectedSize?.price}</span>
                            <button onClick={addToCart} disabled={!selectedSize?.inStock} className="cart-btn">Add to cart</button>
                        </div>
                    </Col>
                </Row>
            }
            
        </Container>
        </Body>
    )
}