import * as React from 'react';
import { Col, Container, Pagination, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { Header } from 'semantic-ui-react';

import { Body } from '../../utils/body';
import { getProducts, getProductsCount, isProductLoading } from '../productSelectors';
import { fetchProducts, resetProductState } from '../productActions';

import { ProductSlideItem } from './product-silde-item';
import './styles/products.scss';




export type IProductsProps = {
    categoryId: string;
    title: string;
};

export const Products: React.FC<{}> = ({}) => {
    const { categoryId, title } = useParams<IProductsProps>();
    const products = useSelector(getProducts);
    const count = useSelector(getProductsCount);
    const isLoading = useSelector(isProductLoading);
    const dispatch = useDispatch();
    const [activePage, setActivePage] = React.useState(0);

    const getPaginationItems = React.useMemo(()=>{
        const items = [];
        if(!count) {
            for (let index = 0; index < count/10; index++) {
                items.push(index);
            }
        }
        else {
            items.push(0);
        }
        return items;
    }, [count])

    React.useEffect(()=>{
        dispatch(fetchProducts({
            page: activePage,
            total: 10,
            category: categoryId,
            newest: true,
        }))
        return(()=>{
            dispatch(resetProductState);
        })
    }, [categoryId])

    const changePage = (page: number) => () => {
        setActivePage(page);
        dispatch(fetchProducts({
            page: page,
            total: 10,
            category: categoryId,
            newest: true,
        }))
    }

    if(isLoading || !products) 
        return <LoadingOverlay 
            active
            spinner={<BounceLoader />}
        />
    return (
        <Body title={`Successful  - ${title}`}>
        <div className='products'>
            <div className='header' style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/1938.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto'
            }}>
                <Container>
                    <Header>
                        {title}
                    </Header>
                </Container>
            </div>
            <Container className='mt-4'>
                <Row>
                    {
                        products.map(product=>(
                            <Col md={4} xs={6} lg={3} key={product.id}>
                                <ProductSlideItem product={product} />
                            </Col>
                        ))
                    }
                    <Col md={12}>
                        <div className='pagination-div'>
                            <Pagination>
                                {
                                    getPaginationItems.map(page=>(
                                        <Pagination.Item key={page} onClick={changePage(page)} active={activePage===page}>
                                            {page+1}
                                        </Pagination.Item>
                                    ))
                                }
                            </Pagination>
                        </div>
                    </Col>  
                </Row>
            </Container>
        </div>
        </Body>
    )
}