import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { getLoggedInUser } from '../user/userSelectors';

export const PrivateRoutes = () => {
    const loggedInUser = useSelector(getLoggedInUser);
  return (
    (loggedInUser && loggedInUser.roles?.includes("admin")) ? <Outlet /> : <Navigate to='/'/>
  )
}