import * as React from 'react';
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon,
    MDBBtn
  } from 'mdb-react-ui-kit';
  import { Icon } from 'semantic-ui-react';

import './styles/footer.scss';


export const Footer: React.FC<{}> = () => {
    return (
        <MDBFooter className='bg-dark text-center text-white'>
      <MDBContainer className='p-4 pb-0'>
        <section className='mb-4'>
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <a rel="noreferrer" href='https://www.facebook.com/profile.php?id=61553838453655&mibextid=ZbWKwL' target='_blank'>
              <Icon fab name='facebook f' />
            </a>
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <Icon fab name='twitter' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <Icon fab name='google' />
          </MDBBtn>
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <Icon fab name='instagram' />
          </MDBBtn>
        </section>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © {new Date().getFullYear()} Copyright:
        <a className='text-white' href='/'>
          Successful
        </a>
      </div>
    </MDBFooter>
    )
}