import axios from "axios";

import { Config } from "./config";

const AxiosLoginInstance = axios.create({
  baseURL : Config.userBaseUrl,
  withCredentials: true,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
    timeout : 1000,
  },
  // .. other options
});

export default AxiosLoginInstance;