/* eslint-disable */

interface IConfig  {
    baseUrl: string;
    userBaseUrl: string;
};

export const Config: IConfig = {
    // baseUrl: 'http://successful.ind.in:8082/api/',
    // userBaseUrl: 'http://successful.ind.in:8083/api/',
    baseUrl: '/product-api/api/',
    userBaseUrl: '/user-api/api/',
    // baseUrl: 'http://localhost:8082/api/',
    // userBaseUrl: 'http://localhost:8083/api/',
};