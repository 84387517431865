import { path, pathOr } from 'ramda';

import { IAppState } from "../../AppState";

import { IProduct, IProductCategory } from './types/product';
import { ISlider } from './types/slider';

export const isProductLoading: (state: IAppState)=> boolean  = pathOr(false, ['product', 'loading']);

export const getProduct: (state: IAppState) => IProduct|undefined  = path(['product', 'productDetails']);

export const getProducts: (state: IAppState) => IProduct[]|undefined  = path(['product', 'productData', 'products']);

export const getProductsCount: (state: IAppState) => number  = pathOr(0, ['product', 'productData', 'total']);

export const getActivePage: (state: IAppState) => number|undefined  = path(['product', 'activePage']);

export const getSliders: (state: IAppState) => ISlider[]|undefined  = path(['product', 'sliders']);

export const getCategories: (state: IAppState) => IProductCategory[]|undefined  = path(['product', 'categories']);