import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { ILogin } from '../types/login';
import { getLoginResponse, isUserLoading } from '../userSelectors';

import './styles/login.scss';
const login: React.FC<InjectedFormProps<ILogin>> = ({
    handleSubmit,
    pristine,
    submitting
}) => {
    const isLoading = useSelector(isUserLoading);
    const response = useSelector(getLoginResponse);
    return (
    <div className="login-container">
         {
                response ?
                response.status==='error' ?
                <Alert variant='danger'>
                    {response.message}
                </Alert>
                :
                <Alert variant='success'>
                    {"Loggedin Successfully!!"}
                </Alert>
                :
                <></>
            }
        <Form onSubmit={handleSubmit}>
            <div className="input-group">
                <label>Email or Phone:</label>
                <Field component={"input"} type="text" id="username" name="username" required />
            </div>

            <div className="input-group">
                <label>Password:</label>
                <Field component={"input"} type="password" id="password" name="password" required />
            </div>

            <div className="remember-me">
                <Field component={"input"} type="checkbox" id="remember" name="remember" />
                <label>Remember me</label>
            </div>
            <div className="forgot-pass">
                <a href="#">Forgot Password</a>
            </div>
            <Button loading={isLoading} variant={"primary"} disabled={pristine || submitting || isLoading} type="submit" className="login-btn">Login</Button>
        </Form>
    </div>
    )
}

const LoginForm = reduxForm<ILogin>({
    initialValues: {
        password: '',
        rememberMe: false,
        username: ''
    },
    form: 'login'
  })(login);
  
export default LoginForm;