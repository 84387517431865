// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-container {
  padding: 1.5rem 1.5rem;
}
.products-container .products-grid {
  height: 50vh;
}

.input-group {
  margin-bottom: 1.5rem;
}
.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-size: 1rem;
  text-align: left;
}
.input-group input, .input-group select, .input-group textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  transition: border-color 0.3s;
}
.input-group input:focus, .input-group select:focus, .input-group textarea:focus {
  border-color: #4CAF50;
}

.login-btn {
  background-color: #4CAF50;
  color: #fff;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}
.login-btn:hover {
  background-color: #45a049;
}

.padding-top-050 {
  padding-top: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/modules/admin/components/styles/products.scss"],"names":[],"mappings":"AACA;EACI,sBAAA;AAAJ;AACI;EACI,YAAA;AACR;;AAGA;EACI,qBAAA;AAAJ;AACI;EACI,cAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AACR;AACI;EACI,WAAA;EACA,eAAA;EACA,eAAA;EACA,sBAAA;EACA,qBAAA;EACA,6BAAA;AACR;AAAQ;EACI,qBAAA;AAEZ;;AAGA;EACI,yBAAA;EACA,WAAA;EACA,oBAAA;EACA,YAAA;EACA,qBAAA;EACA,eAAA;EACA,eAAA;EACA,iCAAA;AAAJ;AACI;EACI,yBAAA;AACR;;AAEA;EACI,mBAAA;AACJ","sourcesContent":["\n.products-container {\n    padding: 1.5rem 1.5rem;\n    .products-grid {\n        height: 50vh;\n    }\n}\n\n.input-group {\n    margin-bottom: 1.5rem;\n    label {\n        display: block;\n        margin-bottom: 0.5rem;\n        color: #555;\n        font-size: 1rem;\n        text-align: left;\n    }\n    input, select, textarea {\n        width: 100%;\n        padding: 0.8rem;\n        font-size: 1rem;\n        border: 1px solid #ddd;\n        border-radius: 0.3rem;\n        transition: border-color 0.3s;\n        &:focus {\n            border-color: #4CAF50;\n        }\n    }\n    \n}\n.login-btn {\n    background-color: #4CAF50;\n    color: #fff;\n    padding: 0.8rem 2rem;\n    border: none;\n    border-radius: 0.3rem;\n    cursor: pointer;\n    font-size: 1rem;\n    transition: background-color 0.3s;\n    &:hover {\n        background-color: #45a049;\n    }\n}\n.padding-top-050 {\n    padding-top: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
