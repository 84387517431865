import { path, pathOr } from 'ramda';

import { IAppState } from "../../AppState";

import { ICartItem } from './types/CartState';

export const getCart: (state: IAppState)=> ICartItem[] = pathOr([], ['cart', 'carts']);

export const isCartLoading: (state: IAppState)=> boolean = pathOr(false, ['cart', 'loading']);

export const isCartVisible: (state: IAppState)=> boolean = pathOr(false, ['cart', 'visible']);

export const getCartCount: (state: IAppState)=> number = pathOr(0, ['cart', 'carts', 'length']);