import React from 'react';
import 'react-bootstrap/helpers';
import 'semantic-ui-css/semantic.min.css';
import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';

import './App.scss';
import './utils/global.scss';
import { Header } from './modules/utils/header';
import { Footer } from './modules/utils/footer';
import { Home } from './modules/home/components/home';
import { Products } from './modules/product/components/products';
import { Product } from './modules/product/components/product';
import { CartComponent } from './modules/cart/components/cart-component';
import { UserPopup } from './modules/user/components/user-popup';
import { About } from './modules/home/components/about';
import { AdminProducts } from './modules/admin/components/Products';
import { PrivateRoutes } from './modules/utils/PrivateRoute';

function App() {
  return (
    <Router>
      <Header />
      <div style={{marginTop: '3.5rem', background: 'white'}}>
        <Routes>
          <Route path="/products/:categoryId/:title" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/admin/products" element={<AdminProducts />} />
          </Route>
        </Routes>
        <UserPopup />
        <CartComponent />
      </div>
      <Footer />
    </Router>
  );
}

export default App;
