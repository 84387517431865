import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { hideProductModal, loadAllProducts, setAdminLoading } from "../adminActions";


export function* deleteProduct({ payload } :PayloadAction<string>) {
    try{
        yield deleteData(payload);
        yield put(loadAllProducts());
        yield put(hideProductModal());
        yield put(setAdminLoading({
            value: false
        }));
        alert("Product Deleted");
    }
    catch(err: any) {
        alert("Error Occured!, please try again later!");
        console.warn(err);
    }
}

const deleteData = async(id: string): Promise<any> => {
    (await AxiosInstance.delete<any>(`products/delete/${id}`));
    return "deleted";
}