import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { hideSliderModal, setAdminLoading } from "../adminActions";
import { fetchSliders } from "../../product/productActions";


export function* deleteSlider({ payload } :PayloadAction<string>) {
    try{
        yield deleteData(payload);
        yield put(fetchSliders());
        yield put(hideSliderModal());
        yield put(setAdminLoading({
            value: false
        }));
        alert("Slider Deleted");
    }
    catch(err: any) {
        alert("Error Occured!, please try again later!");
        console.warn(err);
    }
}

const deleteData = async(id: string): Promise<any> => {
    (await AxiosInstance.delete<any>(`slider/delete/${id}`));
    return "deleted";
}