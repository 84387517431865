import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { fetchSliders } from "../../product/productActions";
import { isCreateProduct } from "../adminSelectors";
import { hideSliderModal, setAdminLoading } from "../adminActions";
import { ISaveSliderAction } from "../types/actions";
import { ISliderRequest } from "../types/sliderRequest";


export function* saveSlider({ payload: { data, id } }: PayloadAction<ISaveSliderAction>) {
    const create: boolean = yield select(isCreateProduct);
    try{
        if(create) {
            const response: unknown = yield save(data);
            if(response){
                yield put(fetchSliders());
                yield put(hideSliderModal());
                yield put(setAdminLoading({
                    value: false
                }));
                alert("Slider Created");
            }
        }
        else {
            const response: unknown = yield update(data, id);
            if(response){
                yield put(fetchSliders());
                yield put(hideSliderModal());
                yield put(setAdminLoading({
                    value: false
                }));
                alert("Slider Updated");
            }
        }
    }
    catch(ex: any) {
        alert("Error Occured!, please try again later!");
        console.warn(ex);
    }
}

const save = async(product: ISliderRequest): Promise<any> => {
    const response = (await AxiosInstance.post<any>(`slider/create`, product));
    return response.data;
}

const update = async(product: ISliderRequest, id?: string): Promise<any> => {
    const response = (await AxiosInstance.put<any>(`slider/update/${id}`, product));
    return response.data;
}