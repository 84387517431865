// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `article, section, footer {
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 3% 2%;
  text-align: center;
}

.header {
  padding: 2rem 0.5rem;
}
.header p {
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/modules/home/components/styles/about.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,2BAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;AAAI;EACI,iBAAA;AAER","sourcesContent":["article, section, footer {\n    background-size: cover;\n    background-position: center;\n    color: #fff;\n    padding: 3% 2%;\n    text-align: center;\n}\n\n.header {\n    padding: 2rem 0.5rem;\n    p {\n        font-size: 1.2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
