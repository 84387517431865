import * as React from 'react';
import { Button, ButtonGroup, Header } from 'semantic-ui-react';
import {AgGridReact} from 'ag-grid-react';
import { ColDef, RowDoubleClickedEvent } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { BounceLoader } from 'react-spinners';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { Body } from '../../utils/body';
import { loadAllProducts, setCreateProduct, showProductCategoryModal, showProductModal, showSliderModal } from '../adminActions';
import { getAdminProducts, isAdminLoading } from '../adminSelectors';
import { IProduct } from '../../product/types/product';



import './styles/products.scss';
import { CategoryModal } from './CategoryModal';
import { ProductModal } from './ProductModal';
import { SliderModal } from './SliderModal';

export interface IProductsProps {

}

export const AdminProducts: React.FC<IProductsProps> = ({}) => {
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = React.useState<IProduct|undefined>(undefined)
    const columnDefs = [{
        field: 'name',
    },
    {
        field: 'productId',
    },
    {
        field: 'title'
    },
    {
        field: 'mrp'
    },
    {
        field: 'price'
    },
    {
        field: 'inStock'
    }];
    React.useEffect(()=>{
        dispatch(loadAllProducts());
    }, [])
    const products = useSelector(getAdminProducts);
    const loading = useSelector(isAdminLoading);
    const rowData = React.useMemo(()=>{
        const data: any[] = [];
        products.forEach(p=>{
            const titles: string[] = [];
            p.sizes.forEach(s=>{
                titles.push(s.title);
            })
            data.push({
                name: p.name,
                productId: p.id,
                title: titles.join(', '),
                mrp: p.sizes[0]?.mrp,
                price: p.sizes[0]?.price,
                inStock: p.sizes[0]?.inStock
            })
        })
        return data;
    }, [products])
    const onRowDoubleClicked = (event: RowDoubleClickedEvent<any>) => {
        const prod= products.find(p=>p.id===event.data?.productId);
        if(prod) {
            setSelectedProduct(prod);
            dispatch(setCreateProduct({
                value: false
            }));
            dispatch(showProductModal());
        }
    }
    const showProductCreateModal = () => {
        setSelectedProduct(undefined);
        dispatch(setCreateProduct({
            value: true
        }));
        dispatch(showProductModal());
    }

    const showCategoryCreateModal = () => {
        dispatch(showProductCategoryModal());
        dispatch(setCreateProduct({
            value: true
        }));
    }

    const showSliderCreateModal = () => {
        dispatch(showSliderModal());
        dispatch(setCreateProduct({
            value: true
        }));
    }


    const defaultColDef = React.useMemo<ColDef>(() => {
        return {
          flex: 1,
          minWidth: 100,
        };
      }, []);
      const autoGroupColumnDef = React.useMemo<ColDef>(() => {
        return {
          minWidth: 200,
        };
      }, []);
    
    return (
        <Body title='Successful - Admin - Products'>
            <div className='products-container'>
                <Header>
                    Products
                    <ButtonGroup floated='right'>
                        <Button onClick={showSliderCreateModal}>Create Slider</Button>
                        <Button onClick={showCategoryCreateModal}>Create Category</Button>
                        <Button onClick={showProductCreateModal}>Create Product</Button>
                    </ButtonGroup>
                    
                </Header>
                <div className='products-grid ag-theme-quartz'>
                    {
                        loading ?
                        <LoadingOverlay 
                            active
                            spinner={<BounceLoader />}
                        />
                        :
                        <AgGridReact 
                            rowData={rowData}
                            columnDefs={columnDefs}
                            onRowDoubleClicked={onRowDoubleClicked}
                            autoGroupColumnDef={autoGroupColumnDef}
                            defaultColDef={defaultColDef}
                        />
                    }
                    <ProductModal product={selectedProduct} />
                    <CategoryModal />
                    <SliderModal />
                </div>
            </div>
        </Body>
    )
}