// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #9195F6;
  padding: 3%;
}
footer a {
  color: #fff;
  text-decoration: none;
  margin: 0 3%;
}`, "",{"version":3,"sources":["webpack://./src/modules/utils/styles/footer.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;EACA,WAAA;AAAJ;AACI;EACI,WAAA;EACA,qBAAA;EACA,YAAA;AACR","sourcesContent":["\nfooter {\n    background-color: #9195F6;\n    padding: 3%;\n    a {\n        color: #fff;\n        text-decoration: none;\n        margin: 0 3%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
