
import { createReducer } from "@reduxjs/toolkit";
import { compose, lensPath, set } from "ramda";

import { IRecievedAdminProductsAction, ISetBooleanDataAction } from "./types/actions";
import { IAdminState } from "./types/state";
import { deleteCategory, deleteProduct, deleteSlider, hideProductCategoryModal, hideProductModal, hideSliderModal, loadAllProducts, recievedAdminProduct, setAdminLoading, setCreateProduct, showProductCategoryModal, showProductModal, showSliderModal, submitProduct, submitProductCategory, submitSlider } from "./adminActions";
import { IProduct } from "../product/types/product";

const INITIAL_STATE: IAdminState = {
    loading: false,
    createProduct: false,
    productCategoryModal: false,
    productModal: false,
    products: [],
    sliderModal: false,
};

const setAdminLoadingRed = ({ value }: ISetBooleanDataAction) => set<IAdminState, boolean>(
    lensPath(['loading']), value
);

const setCreateProductRed = ({ value }: ISetBooleanDataAction) => set<IAdminState, boolean>(
    lensPath(['createProduct']), value
);

const showProductModalRed = () => set<IAdminState, boolean>(
    lensPath(['productModal']), true
);

const hideProductModalRed = () => set<IAdminState, boolean>(
    lensPath(['productModal']), false
);

const showSliderModalRed = () => set<IAdminState, boolean>(
    lensPath(['sliderModal']), true
);

const hideSliderModalRed = () => set<IAdminState, boolean>(
    lensPath(['sliderModal']), false
);

const showProductCategoryModalRed = () => set<IAdminState, boolean>(
    lensPath(['productCategoryModal']), true
);

const hideProductCategoryModalRed = () => set<IAdminState, boolean>(
    lensPath(['productCategoryModal']), false
);

const recievedAdminProductRed = ({ products }: IRecievedAdminProductsAction) => compose(set<IAdminState, IProduct[]>(
    lensPath(['products']), products
),set<IAdminState, boolean>(
    lensPath(['loading']), false
) );

export const adminReducers = createReducer(INITIAL_STATE, builder=>{
    builder.addCase(setCreateProduct, (state, action) => setCreateProductRed(action.payload)(state))
    .addCase(setAdminLoading, (state, action) => setAdminLoadingRed(action.payload)(state))
    .addCase(showProductModal, (state, _action) => showProductModalRed()(state))
    .addCase(hideProductModal, (state, _action) => hideProductModalRed()(state))
    .addCase(showSliderModal, (state, _action) => showSliderModalRed()(state))
    .addCase(hideSliderModal, (state, _action) => hideSliderModalRed()(state))
    .addCase(hideProductCategoryModal, (state, _action) => hideProductCategoryModalRed()(state))
    .addCase(showProductCategoryModal, (state, _action) => showProductCategoryModalRed()(state))
    .addCase(recievedAdminProduct, (state, action) => recievedAdminProductRed(action.payload)(state))
    .addCase(loadAllProducts, (state, _action) => setAdminLoadingRed({value: true})(state))
    .addCase(submitProduct, (state, _action) => setAdminLoadingRed({value: true})(state))
    .addCase(submitProductCategory, (state, _action) => setAdminLoadingRed({value: true})(state))
    .addCase(submitSlider, (state, _action) => setAdminLoadingRed({value: true})(state))
    .addCase(deleteProduct, (state, _action) => setAdminLoadingRed({value: true})(state))
    .addCase(deleteCategory, (state, _action) => setAdminLoadingRed({value: true})(state))
    .addCase(deleteSlider, (state, _action) => setAdminLoadingRed({value: true})(state))
})

