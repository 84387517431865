import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, Form, InjectedFormProps, WrappedFieldProps, reduxForm } from 'redux-form';
import { Button, Segment, SegmentGroup } from 'semantic-ui-react';

import { FileUploadField } from '../../../utils/FileUploadField';
import { IProductRequest } from '../types/productRequest';
import { isAdminLoading, isCreateProduct } from '../adminSelectors';
import { IProduct } from '../../product/types/product';
import { submitProduct } from '../adminActions';
import { getCategories } from '../../product/productSelectors';

import './styles/products.scss';

export interface IProductFormProps {
    product?: IProduct;
}

const renderSizes: React.FC<WrappedFieldProps> = ({
    input
}) => {
    const addSize = () => {
        const values: any[] = JSON.parse(JSON.stringify((input.value || []) as any[])); 
        values.push({
            title: '',
            description: '',
            mrp: 0,
            price: 0,
            inStock: 0,
            imageUrl: [],
            id: '',
        });
        input.onChange(values);
    }
    return (
        <div style={{width: '100%'}}>
            <h4 className="ui horizontal divider">
                Sizes
            </h4>
            <div style={{width: '100%'}}>
                <Button type='button' onClick={addSize}>
                    Add Size
                </Button>
                <SegmentGroup>
                    {
                        ((input.value || []) as any[]).map((_value,ind)=>(
                            <Segment key={ind} padded>
                                <div className="input-group">
                                    <label>Size Title:</label>
                                    <Field 
                                        component={"input"} 
                                        type="text" 
                                        id={`${input.name}[${ind}].title`}
                                        name={`${input.name}[${ind}].title`}
                                        required 
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Size Description:</label>
                                    <Field 
                                        component={"textarea"} 
                                        type="text" 
                                        id={`${input.name}[${ind}].description`}
                                        name={`${input.name}[${ind}].description`}
                                        required 
                                    />
                                </div>
                                <div className="input-group">
                                    <label>SKU:</label>
                                    <Field 
                                        component={"input"} 
                                        type="text" 
                                        id={`${input.name}[${ind}].sku`}
                                        name={`${input.name}[${ind}].sku`}
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Size MRP:</label>
                                    <Field 
                                        component={"input"} 
                                        type="number" 
                                        id={`${input.name}[${ind}].mrp`}
                                        name={`${input.name}[${ind}].mrp`}
                                        required 
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Size Price:</label>
                                    <Field 
                                        component={"input"} 
                                        type="number" 
                                        id={`${input.name}[${ind}].price`}
                                        name={`${input.name}[${ind}].price`}
                                        required 
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Size Instock:</label>
                                    <Field 
                                        component={"input"} 
                                        type="number" 
                                        id={`${input.name}[${ind}].inStock`}
                                        name={`${input.name}[${ind}].inStock`}
                                        required 
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Size Images:</label>
                                    <Field 
                                        component={FileUploadField} 
                                        id={`${input.name}[${ind}].imageUrl`}
                                        name={`${input.name}[${ind}].imageUrl`}
                                        required
                                    />
                                </div>
                            </Segment>
                        ))
                    }
                    </SegmentGroup>
            </div>
        </div>
    )
}

const renderProductForm: React.FC<InjectedFormProps<IProductRequest>> = ({
    pristine,
    submitting,
    handleSubmit
}) => {
    const isLoading = useSelector(isAdminLoading);
    const isCreate = useSelector(isCreateProduct);
    const categories = useSelector(getCategories);
    return (
        <Form name={'product-form'} onSubmit={handleSubmit}>
            <h4 className="ui horizontal divider">
                Product Details
            </h4>
            <div className="input-group">
                <label>Product Name:</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="name" 
                    name="name" 
                    required 
                />
            </div>
            <div className="input-group">
                <label>Product Category:</label>
                <Field 
                    component={"select"} 
                    id="categoryId" 
                    name="categoryId" 
                    required 
                >
                    <option></option>
                    {
                        (categories||[]).map(cat=>(
                            <option key={cat.id} value={cat.id}>{cat.category}</option>
                        ))
                    }
                </Field>
            </div>
            <div className="input-group">
                <label>SUB Category:</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="extra" 
                    name="extra" 
                />
            </div>
            <div className="input-group">
                <label>HSN:</label>
                <Field 
                    component={"input"} 
                    type="text" 
                    id="hsn" 
                    name="hsn" 
                />
            </div>
            <div className="input-group">
                <label>Product Images:</label>
                <Field 
                    component={FileUploadField} 
                    id="images" 
                    name="images" 
                    required
                />
            </div>
            <div className="input-group">
                <Field 
                    component={renderSizes} 
                    id="sizes" 
                    name="sizes" 
                    required
                />
            </div>
            <Button loading={isLoading} variant={"primary"} disabled={pristine || submitting || isLoading} type="submit" className="login-btn">{isCreate? 'Create':'Update'} Product</Button>
        </Form>
    )
}

const onSubmit = (values: IProductRequest, dispatch: any) => {
    dispatch(submitProduct({
        data: values,
        id: values?.id
    }));
  };

export const ProductForm = connect(
    (_state, props: IProductFormProps) => {
        return {
            initialValues: props.product ? {
                ...props.product,
                images: props.product.imageUrl,
                categoryId: props.product?.category?.id
            } : {
                sizes: [
                    {
                        title: ''
                    }
                ]
            }
        }
    },
    null
)(reduxForm<IProductRequest>({
    form: 'product',
    enableReinitialize: true,
    onSubmit,
  })(renderProductForm));
