// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card {
  min-width: 15rem;
  margin: 2%;
  padding: 3%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  text-align: center;
}
.product-card .product-image {
  max-width: 100%;
  height: auto;
  border-radius: 0.3rem;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}
.product-card .product-info {
  margin-top: 2%;
}
.product-card .product-info h3 {
  margin: 1% 0;
  font-size: 1.2rem;
}
.product-card .product-info p {
  margin: 1% 0;
  font-size: 1.2rem;
  color: #555;
}

.add-to-cart-btn {
  display: inline-block;
  padding: 0.2rem 1em;
  background-color: #9195F6;
  color: #fff !important;
  text-decoration: none;
  border-radius: 0.3rem;
  transition: background-color 0.3s;
}
.add-to-cart-btn:hover {
  background-color: #6F73D2;
}

.card-div {
  position: relative !important;
  margin: 0.4rem 0 !important;
}
.card-div .label-tag {
  position: absolute !important;
  top: 0.5rem;
  right: -0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/modules/product/components/styles/product-slide-item.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;EACA,WAAA;EACA,0CAAA;EACA,qBAAA;EACA,kBAAA;AACJ;AAAI;EACI,eAAA;EACA,YAAA;EACA,qBAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;AAER;AAAI;EACI,cAAA;AAER;AADQ;EACI,YAAA;EACA,iBAAA;AAGZ;AADQ;EACI,YAAA;EACA,iBAAA;EACA,WAAA;AAGZ;;AAEA;EACI,qBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,qBAAA;EACA,qBAAA;EACA,iCAAA;AACJ;AAAI;EACI,yBAAA;AAER;;AACA;EACI,6BAAA;EACA,2BAAA;AAEJ;AADI;EACI,6BAAA;EACA,WAAA;EACA,cAAA;AAGR","sourcesContent":[".product-card {\n    min-width: 15rem;;\n    margin: 2%;\n    padding: 3%;\n    background-color: rgba(255, 255, 255, 0.8);\n    border-radius: 0.5rem;\n    text-align: center;\n    .product-image {\n        max-width: 100%;\n        height: auto;\n        border-radius: 0.3rem;\n        display: block;\n        margin: 0 auto;\n        object-fit: cover;\n    }\n    .product-info {\n        margin-top: 2%;\n        h3 {\n            margin: 1% 0;\n            font-size: 1.2rem;\n        }\n        p {\n            margin: 1% 0;\n            font-size: 1.2rem;\n            color: #555;\n        }\n    }\n    \n}\n.add-to-cart-btn {\n    display: inline-block;\n    padding: 0.2rem 1em;\n    background-color: #9195F6;\n    color: #fff !important;\n    text-decoration: none;\n    border-radius: 0.3rem;\n    transition: background-color 0.3s;\n    &:hover {\n        background-color: #6F73D2;\n    }\n}\n.card-div {\n    position: relative !important;\n    margin: 0.4rem 0 !important;\n    .label-tag {\n        position: absolute !important;\n        top: 0.5rem;\n        right: -0.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
