import { pathOr } from 'ramda';

import { IAppState } from "../../AppState";
import { IProduct } from '../product/types/product';

export const isAdminLoading: (state: IAppState)=> boolean = pathOr(false, ['admin', 'loading']);

export const isCreateProduct: (state: IAppState)=> boolean = pathOr(false, ['admin', 'createProduct']);

export const isProductModalVisible: (state: IAppState)=> boolean = pathOr(false, ['admin', 'productModal']);

export const isProductCategoryModalVisible: (state: IAppState)=> boolean = pathOr(false, ['admin', 'productCategoryModal']);

export const isSliderModalVisible: (state: IAppState)=> boolean = pathOr(false, ['admin', 'sliderModal']);

export const getAdminProducts: (state: IAppState)=> IProduct[] = pathOr([], ['admin', 'products']);