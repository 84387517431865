import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";

import AxiosInstance from "../../../AxiosInstance";
import { isCreateProduct } from "../adminSelectors";
import { hideProductModal, loadAllProducts, setAdminLoading } from "../adminActions";
import { ISaveProductAction } from "../types/actions";
import { IProductRequest } from "../types/productRequest";

export function* saveProduct({ payload: { data, id } }: PayloadAction<ISaveProductAction>) {
    const create: boolean = yield select(isCreateProduct);
    try{
        if(create) {
            const response: unknown = yield save(data);
            if(response){
                yield put(loadAllProducts());
                yield put(hideProductModal());
                yield put(setAdminLoading({
                    value: false
                }));
                alert("Product Created");
            }
        }
        else {
            const response: unknown = yield update(data, id);
            if(response){
                yield put(loadAllProducts());
                yield put(hideProductModal());
                yield put(setAdminLoading({
                    value: false
                }));
                alert("Product Updated");
            }
        }
    }
    catch(ex: any) {
        alert("Error Occured!, please try again later!");
        yield put(setAdminLoading({
            value: false
        }));
        console.warn(ex);
    }
}

const save = async(product: IProductRequest): Promise<any> => {
    const response = (await AxiosInstance.post<any>(`products/create`, product));
    return response.data;
}

const update = async(product: IProductRequest, id?: string): Promise<any> => {
    const response = (await AxiosInstance.put<any>(`products/update/${id}`, product));
    return response.data;
}