import { createAction } from "@reduxjs/toolkit";

import { IFetchProductAction, IFetchProductsAction, IReceivedProductAction, IReceivedProductCategoriesAction, IReceivedProductsAction, IReceivedSlidersAction } from "./types/action";


export const FETCH_PRODUCT = "product/FETCH_PRODUCT";
export const RECIEVED_PRODUCT = "product/RECIEVED_PRODUCT";
export const FETCH_PRODUCTS = "product/FETCH_PRODUCTS";
export const RECIEVED_PRODUCTS = "product/RECIEVED_PRODUCTS";
export const FETCH_PRODUCT_CATEGORIES = "product/FETCH_PRODUCT_CATEGORIES";
export const RECIEVED_PRODUCTS_CATEGORIES  = "product/RECIEVED_PRODUCTS_CATEGORIES";
export const FETCH_SLIDERS = "product/FETCH_SLIDERS";
export const RECIEVED_SLIDERS  = "product/RECIEVED_SLIDERS";
export const RESET = "product/RESET";

export const fetchProduct = createAction<IFetchProductAction>(FETCH_PRODUCT);

export const recievedProduct = createAction<IReceivedProductAction>(RECIEVED_PRODUCT);

export const fetchProducts = createAction<IFetchProductsAction>(FETCH_PRODUCTS);

export const recievedProducts = createAction<IReceivedProductsAction>(RECIEVED_PRODUCTS);

export const fetchProductCategories = createAction(FETCH_PRODUCT_CATEGORIES);

export const recievedProductCategories = createAction<IReceivedProductCategoriesAction>(RECIEVED_PRODUCTS_CATEGORIES);

export const fetchSliders = createAction(FETCH_SLIDERS);

export const recievedSliders = createAction<IReceivedSlidersAction>(RECIEVED_SLIDERS);

export const resetProductState = createAction(RESET);