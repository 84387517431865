import * as React from 'react';
import { Link } from 'react-router-dom';

import { IProduct } from '../types/product';

import './styles/product-slide-item.scss';
import { Card, Label } from 'semantic-ui-react';

export interface ProductSlideItemProps {
    product: IProduct;
}

export const ProductSlideItem: React.FC<ProductSlideItemProps> = ({
    product,
}) => {
    return (
        
        <Card
                className='card-div'
                image={product.imageUrl?.[0]||product.sizes?.[0]?.imageUrl?.[0] ||'default.png'}
                header={product.name}
                meta={product.extra ? <Label className='label-tag' as='a' color='teal' tag>
                {product.extra}
              </Label> : undefined}
                extra={<Link to={`/product/${product.id}`} className="add-to-cart-btn">View</Link>}
            />
    )
}